<template>
  <picture
    class="image"
    @click="emitClick"
  >
    <!-- todo check ssr -->
    <source
      v-for="url in urlList"
      :key="url.srcset"
      :media="url.media"
      :srcset="url.srcset"
      :type="url.type"
      :sizes="url.sizes"
    >
    <img
      :src="getAbsoluteUrl(src)"
      :srcset="withRetina ? defaultUrl : undefined"
      :alt="alt ? alt : 'Image'"
      :loading="loading"
      v-bind="$attrs"
    >
    <meta v-for="item in itempropList" :key="item" :itemprop="item" :content="getAbsoluteUrl(src)">
  </picture>
</template>

<script lang="ts" setup>
import { computed, defineComponent } from 'vue'
import type { PropType } from 'vue'
import { getUrlWithTrailingSlashes } from '@/utils/url'

type LoadingProp = 'lazy' | 'eager'

defineComponent({ name: 'AImage', inheritAttrs: false })

const props = defineProps({
  src: {
    type: String,
    required: true
  },
  mobileSrc: {
    type: String,
    default: undefined
  },
  alt: {
    type: String,
    default: undefined
  },
  loading: {
    type: String as PropType<LoadingProp>,
    default: 'lazy'
  },
  mobileSizes: {
    type: String,
    default: undefined
  },
  includeLaptop: Boolean,
  fromPublic: Boolean,
  withRetina: Boolean,
  withWebp: Boolean,
  itempropList: {
    type: Array as PropType<('image' | 'logo')[]>,
    default: () => []
  }
})

const emit = defineEmits({
  click: (event: MouseEvent) => event instanceof MouseEvent
})

const runtimeConfig = useRuntimeConfig()

const emitClick = (event: MouseEvent): void => {
  emit('click', event)
}

const getAbsoluteUrl = (url: string): string => [
  props.fromPublic ? runtimeConfig.app.cdnURL : runtimeConfig.public.cdnUrl,
  getUrlWithTrailingSlashes(url, false)
].join('')

const setExtension = (url: string): string => {
  return url.replace(/\.(png|jpg|jpeg)/gi, '.webp')
}

const getSrcSetWithRetina = (url: string, withExtension = false): string => {
  const dimsRegex = /[0-9]+x[0-9]+/g
  const widthRegex = /[0-9]+w/g

  return url
    ?.split(', ')
    .map((part) => {
      const newSrc = getAbsoluteUrl(withExtension ? setExtension(part) : part)

      if (props.withRetina) {
        const regexUrlMatch = part.match(dimsRegex) ?? []
        const widthRegexUrlMatch = part.match(widthRegex) ?? []
        let newSrcX2 = newSrc

        regexUrlMatch.forEach((match) => {
          const dimsX2 = match.split('x').map(dim => +dim * 2).join('x')
          newSrcX2 = newSrcX2.replace(match, dimsX2)
        })

        if (widthRegexUrlMatch.length !== 0) {
          widthRegexUrlMatch.forEach((match) => {
            const widthX2 = `${parseInt(match) * 2}w`
            newSrcX2 = newSrcX2.replace(match, widthX2)
          })
        } else {
          newSrcX2 = `${newSrcX2} 2x`
        }

        return `${newSrc}, ${newSrcX2}`
      }

      return newSrc
    })
    .join(', ')
}

const defaultUrl = computed(() => getSrcSetWithRetina(props.src))
const urlList = computed(() => {
  const urlList = []

  if (props.mobileSrc) {
    if (props.withWebp && !props.mobileSrc.endsWith('.webp')) {
      urlList.push({
        srcset: getSrcSetWithRetina(props.mobileSrc, true),
        media: `(max-width: ${props.includeLaptop ? 1199 : 1023}px)`,
        type: 'image/webp',
        sizes: props.mobileSizes
      })
    }

    urlList.push({
      srcset: getSrcSetWithRetina(props.mobileSrc),
      media: `(max-width: ${props.includeLaptop ? 1199 : 1023}px)`,
      sizes: props.mobileSizes
    })
  }

  if (props.withWebp && !props.src?.endsWith('.webp')) {
    urlList.push({ srcset: getSrcSetWithRetina(props.src, true) })
  }

  return urlList
})
</script>

<style lang="postcss">
.image {
  display: block;
}

.image img {
  width: 100%;
}
</style>
